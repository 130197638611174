import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../Button'
import { sendApplicationForm } from '../../../services/api'
import UpChevron from '../../../assets/images/icons/svg/icon-up-chevron-orange.svg'
import RightChevron from '../../../assets/images/icons/svg/icon-right-chevron-white.svg'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Modal from '../Modal'

// Error messages
const REQUIRED = 'Required field'
const INVALID_EMAIL = 'Invalid email address'
const NO_LONGER_THAN = 'It can not be longer than '
const ACCEPTED_FILE_TYPES = ['application/pdf']

const FormApplication = ({ sheetName, gdprText }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const fileRef = useRef(null)
  const [fileName, setFileName] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [apiError, setApiError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const onSubmit = async data => {
    if (!executeRecaptcha) return

    try {
      setIsSuccess(false)
      setApiError('')
      setLoading(true)
      const newData = { ...data }
      newData.sheetName = sheetName

      const result = await executeRecaptcha('application_form')
      newData.token = result

      const response = await sendApplicationForm(newData)
      if (response?.status === 200 && response?.data?.status) {
        setIsSuccess(true)
      }
    } catch (error) {
      setApiError(error?.response?.data?.message || 'Request failed.')
    } finally {
      setLoading(false)
    }
  }

  const handleFileUpload = () => {
    fileRef.current.click()
  }

  const { ref, ...rest } = register('file', {
    required: REQUIRED,
    validate: {
      fileType: data => {
        const file = data[0]
        setFileName(file.name)
        if (ACCEPTED_FILE_TYPES.includes(file.type)) {
          return true
        }
        return 'Invalid document type. Valid types: pdf'
      },
    },
  })

  const toggleModalVisibility = () => setIsVisible(prev => !prev)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex flex-col gap-8 lg:grid lg:grid-cols-2 lg:gap-x-10 lg:gap-y-8 pt-2.5">
          <div>
            <label
              className="flex flex-col justify-start items-start"
              htmlFor="input_name"
            >
              <input
                id="input_name"
                type="text"
                className={classNames('form-application-element', {
                  'form-error': errors.fullName,
                })}
                placeholder="Full Name*"
                {...register('fullName', {
                  required: REQUIRED,
                  maxLength: { value: 80, message: NO_LONGER_THAN + 80 },
                })}
              />
              {errors?.fullName && (
                <span className="error-text">{errors.fullName.message}</span>
              )}
            </label>
          </div>
          <div>
            <input
              id="input_email"
              type="email"
              className={classNames('form-application-element', {
                'form-error': errors.email,
              })}
              placeholder="Your Email*"
              {...register('email', {
                required: REQUIRED,
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: INVALID_EMAIL,
                },
              })}
            />
            {errors?.email && (
              <span className="error-text">{errors.email.message}</span>
            )}
          </div>
          <div>
            <input
              id="input_phone"
              type="tel"
              className={classNames('form-application-element', {
                'form-error': errors.phone,
              })}
              placeholder="Phone Number"
              {...register('phone', {
                maxLength: 13,
              })}
            />
            {errors?.phone && (
              <span className="error-text">{errors.phone.message}</span>
            )}
          </div>
          <div className="flex flex-col">
            <div
              className={classNames(
                'form-application-element flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:p-[14.5px] space-y-4 lg:space-y-0 !pl-6',
                {
                  'form-error': errors.file,
                }
              )}
            >
              {fileName ? (
                <p>{fileName}</p>
              ) : (
                <p className="tracking-wide text-gray">Resume/Portfolio*</p>
              )}
              <button
                type="button"
                onClick={handleFileUpload}
                className="flex flex-row bg-primary-10 text-primary border-1 border-primary typo-bold-16 py-[11px] px-[32px] rounded-[4px]"
              >
                <p>Upload Resume</p>
                <UpChevron className="ml-2" />
              </button>
              <input
                id="input_file"
                ref={e => {
                  ref(e)
                  fileRef.current = e
                }}
                type="file"
                accept="application/pdf"
                className="hidden invisible"
                {...rest}
              />
            </div>
            {errors?.file && (
              <span className="error-text">{errors.file.message}</span>
            )}
          </div>
          <div className="col-span-2 flex flex-col justify-center items-center">
            <div className="col-span-2 mb-8">
              <div className="flex items-center mb-2">
                <input
                  id="input_gdpr"
                  type="checkbox"
                  value=""
                  {...register('gdpr', { required: REQUIRED })}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="input_gdpr"
                  className="ml-2 typo-medium-14 text-gray"
                >
                  I have read and agree to
                  <span
                    onClick={toggleModalVisibility}
                    className="text-secondary font-bold hover:cursor-pointer hover:underline mx-1"
                  >
                    Apps's Terms of Service
                  </span>
                </label>
              </div>
              {errors?.gdpr && (
                <p className="error-text">{errors.gdpr.message}</p>
              )}
            </div>
            <Button
              type="submit"
              text="Apply Now"
              icon={<RightChevron />}
              loading={loading}
            />
            <div className="mt-2 typo-medium-14 text-gray">
              This site is protected by reCAPTCHA and the Google
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-1"
              >
                Privacy Policy
              </a>
              and
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-1"
              >
                Terms of Service
              </a>
              apply.
            </div>
            {apiError && (
              <div className="p-4 rounded text-[12px] error-alert mt-4 flex items-center">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="22"
                    height="22"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                    />
                  </svg>
                </span>
                {apiError}
              </div>
            )}
            {isSuccess && (
              <div className="p-4 rounded text-[12px] success-alert mt-4 flex items-center">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="22"
                    height="22"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    />
                  </svg>
                </span>
                Your message has been delivered successfully.
              </div>
            )}
          </div>
        </div>
      </form>
      <Modal
        isVisible={isVisible}
        toggleModalVisibility={toggleModalVisibility}
        content={gdprText?.html}
      />
    </>
  )
}

export default FormApplication
