import React from 'react'
import { graphql } from 'gatsby'
import { nanoid } from 'nanoid'

import Seo from '../components/Seo'

import FormApplication from '../components/Common/FormApplication'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { sheetName } = frontmatter

  const jobInfos =
    frontmatter.infos && Object.keys(frontmatter.infos).length > 0
      ? Object.keys(frontmatter.infos).map(v => ({ ...frontmatter.infos[v] }))
      : []

  const employmentType = frontmatter.infos.employment
    ? frontmatter.infos.employment.value.toLowerCase()
    : ''

  const jobLocation = frontmatter.infos.location
    ? frontmatter.infos.location.value.toLowerCase() === 'remote'
      ? 'TELECOMMUTE'
      : frontmatter.infos.location.value
    : ''

  return (
    <>
      <Seo title={frontmatter.title} slug={frontmatter.slug}>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'JobPosting',
            title: frontmatter.title,
            description: `<p>${frontmatter.description}</p>`,

            datePosted: frontmatter.updateDate,
            validThrough: `${frontmatter.updateDate}T00:00`,
            jobLocationType: jobLocation,
            applicantLocationRequirements: {
              '@type': 'Country',
              name: 'TR',
            },
            employmentType: employmentType,
            hiringOrganization: {
              '@type': 'Organization',
              name: 'APPS',
              sameAs: 'https://apps.com.tr',
              logo: 'https://apps.com.tr/images/logo.png',
            },
          })}
        </script>
      </Seo>
      <div className="xl:grid xl:grid-cols-12 xl:gap-10 pt-10 xl:pt-20">
        <div className="xl:col-span-10 xl:col-start-2">
          <div className="border-b border-solid border-gray-dark pb-8 mb-6 lg:mb-8">
            <div className="mb-8">
              <h1 className="typo-bold-24 lg:typo-bold-40 text-secondary">
                {frontmatter.heading}
              </h1>
              <p className="typo-medium-14 lg:typo-medium-16 text-gray mt-4 lg:mt-5">
                {frontmatter.description}
              </p>
            </div>
            {jobInfos.length > 0 && (
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10">
                {jobInfos.map(({ label, value }) => (
                  <div
                    key={`career-item-${nanoid()}`}
                    className="grid grid-cols-1 gap-2"
                  >
                    <p className="typo-bold-14-wide2x lg:typo-bold-16-wide2x text-primary">
                      {label}
                    </p>
                    <p className="typo-medium-14 lg:typo-medium-16 text-secondary">
                      {value}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className="career-detail-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <div className="my-10 border-t border-solid border-gray-dark">
        <h2 className="my-12 text-secondary typo-bold-16 !text-[20px] text-center">
          Submit Your Application
        </h2>
        <FormApplication sheetName={sheetName} gdprText={data.gdprText} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heading
        summary
        description
        slug
        sheetName
        infos {
          employment {
            label
            value
          }
          experience {
            label
            value
          }
          level {
            label
            value
          }
          location {
            label
            value
          }
        }
        updateDate
      }
      html
    }
    gdprText: markdownRemark(
      frontmatter: { content: { eq: "gdpr_application_form" } }
    ) {
      html
    }
  }
`
